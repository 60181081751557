<template>
    <modal ref="crearListadoChequeo" tamano="modal-lg"
        :titulo="`${accion ? 'Editar' : 'Crear'} lista de chequeo`"
        :adicional="accion ? 'Guardar' : 'Crear'"
        @adicional="action"
    >
        <div class="row justify-content-center m-3 f-15">
            <div class="col-12 my-2">
                <ValidationObserver ref="validator">
                    <ValidationProvider v-slot="{ errors }" name="nombre" rules="required">
                        <p class="input-label-top">Nombre de la lista de chequeo</p>
                        <el-input v-model="nombre" placeholder="Nombre de la lista" class="w-100" size="small" />
                        <vee-error :text="errors[0]"></vee-error>
                    </ValidationProvider>
                </ValidationObserver>
            </div>
            <div class="container-fluid">
                <titulo-divisor titulo="Actividades" class="mb-4">
                    <div class="row icon-option">
                        <el-tooltip content="Crear actividad" placement="bottom" effect="light" visible-arrow>
                            <i class="icon-plus-circle f-30 cr-pointer" @click="addModel" />
                        </el-tooltip>
                    </div>
                </titulo-divisor>
                <div v-for="(data, index) in actividades" :key="index" class="row mb-4">
                    <div class="col-9 my-auto position-relative">
                        <p class="input-label-top-absolute">Actividad</p>
                        <p v-if="data.actividad == ''" class="text-danger position-absolute" style="top: 0; right: 22px; z-index: 5;">*</p>
                        <el-input v-model="data.actividad"
                            placeholder="Nombre de la actividad"
                            class="w-100 br-5" size="small"
                            :style=" data.actividad == '' ? 'border: solid 1px red;' : '' "
                        />
                    </div>
                    <div class="col-2 my-auto position-relative d-flex">
                        <p class="input-label-top-absolute">Peso</p>
                        <p v-if="data.peso == undefined" class="text-danger position-absolute" style="top: 0; right: 40px; z-index: 5;">*</p>
                        <el-input-number v-model="data.peso"
                            :controls="false" class="w-100 br-5" size="small"
                            :style=" data.peso == undefined ? 'border: solid 1px red;' : '' "
                        />
                        <p class="ml-2 my-auto">%</p>
                    </div>
                    <div class="col-1 my-auto icon-option">
                        <el-tooltip content="Eliminar" placement="bottom" effect="light" visible-arrow>
                            <i class="icon-trash-can-outline f-26 cr-pointer" @click="deleteActivity(index)" />
                        </el-tooltip>
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    data(){
        return {
            accion: 0, // Crear 0, Editar 1
            title: '',
            nombre: '',
            id: null,
            id_configuracion_control_piso_lista_chequeo: undefined,
            actividades:[]
        }
    },
    methods: {
        toggle(){
            this.clear()
            this.accion = 0
            this.$refs.crearListadoChequeo.toggle()
        },
        edit(dataEdit){
            this.clear()
            this.accion = 1
            this.nombre = dataEdit.nombre
            this.actividades = _.cloneDeep(dataEdit.actividades)
            this.id = dataEdit.id
            this.id_configuracion_control_piso_lista_chequeo = dataEdit.id_configuracion_control_piso_lista_chequeo
            this.$refs.crearListadoChequeo.toggle()
        },
        editPredeterminada(dataEdit){
            this.clear()
            this.accion = 1
            this.title = dataEdit.lista_chequeo
            this.nombre = dataEdit.lista_chequeo
            this.actividades = _.cloneDeep(dataEdit.actividades)
            this.id = dataEdit.id
            this.id_configuracion_control_piso_lista_chequeo = dataEdit.id
            this.$refs.crearListadoChequeo.toggle()
        },
        async action(){
            let totalPeso = 0
            let validator = true
            if (!await this.$refs.validator.validate()) return
            this.actividades.forEach( el => {
                totalPeso += el.peso
                if (el.actividad == '') validator = false
                if (el.peso == undefined) validator = false
            })
            if (!validator) return
            if (totalPeso > 100) {
                this.$notify({title: 'El peso total no puede ser mayor que 100', type:'info'})
            }else if(totalPeso < 100){
                this.$notify({title: 'El peso total no puede ser menor que 100', type:'info'})
            }else{
                this.$emit(`${this.accion ? 'editar' : 'crear'}`, {
                    id: this.id_configuracion_control_piso_lista_chequeo ?? null,
                    nombre: this.nombre,
                    actividades: this.actividades,
                    id_configuracion_control_piso_lista_chequeo: this.id_configuracion_control_piso_lista_chequeo})
                this.$refs.crearListadoChequeo.toggle()
            }
        },
        deleteActivity(index){
            this.actividades = this.actividades.filter( (_,idx) => idx !== index)
        },
        addModel(){
            this.actividades.push({id: null, id_lista_chequeo: null, actividad: '', peso: ''})
        },
        clear(){
            this.nombre = ''
            this.id = null
            this.id_configuracion_control_piso_lista_chequeo = undefined
            this.actividades = []
        }
    }
}
</script>

<style lang="css" scoped>
</style>
